import React from "react"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import * as styles from "./customer-resources.module.scss"
import Seo from "../../components/seo"

const CustomerResources = ({ pageContext, location }) => {
  return (
    <Layout>
      <InnerHeader
        pageTitle="Customer Resources"
        pageContext={pageContext}
        location={location}
      />
      <Seo title="Customer Resources" />
      <nav className={`inner-y-padding ${styles.wrapper}`} aria-label="in-page">
        <div className="inner-container-alt">
          <div className={` ${styles.links}`}>
            <h2 className="h3">Find out more</h2>
            <ul>
            <li>
                <Link
                  to="./for-our-applicants"
                  className="biggerLink"
                  title="For Our Applicants"
                >
                  For Our Applicants
                </Link>
              </li>
              <li>
                <Link
                  to="./for-our-consumers"
                  className="biggerLink"
                  title="For Our Consumers"
                >
                  For Our Consumers
                </Link>
              </li>
              <li>
                <Link
                  to="./for-our-dealers"
                  className="biggerLink"
                  title="For Our Dealers"
                >
                  For Our Dealers
                </Link>
              </li>
              <li>
                <Link
                  to="./join-our-family-of-authorized-dealers"
                  className="biggerLink"
                  title="Become a Dealer"
                >
                  Become a Dealer
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </Layout>
  )
}

export default CustomerResources
